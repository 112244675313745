.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.inputLabel {
  margin-left: 5px;
}

.keywordFound {
  background-color: lightgreen;
  width: 50%;
  height: 100%;
}

.keywordClosing {
  background-color: #8bbd78;
  width: 50%;
  height: 100%;
}

.keywordBuilding {
  background-color: rgb(247, 189, 83);
  width: 50%;
  height: 100%;
}

.keywordQualifying {
  background-color: lightskyblue;
  width: 50%;
  height: 100%;
}

.keywordNotFound {
  width: 50%;
  height: 100%;
}

.borderRight {
  border-right: 1px dotted lightgray;
}

.colorExplanations {
  margin-top: 30px;
  display: flex;
}

.customerColorExplanation {
  margin-top: 20px;
  width: 16%;
  margin-right: auto;
  white-space: nowrap;
}

.customerColumn {
  border-bottom: 1px solid black;
  width: 200px;
  margin-bottom: 10px;
}

.otherInfo {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}

.column-explanation {
  display: flex;
  justify-content: right;
}

.column-explanation > * {
  padding: 20px;
}

.salesColumnHeader {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.projectColumnHeader {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.customersWithOtherKeywords {
  display: flex;
}
